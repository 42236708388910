import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import "./sass/_main.sass";

createApp(App).use(store).use(router).mount("#app");
