
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  components: {
    Header: defineAsyncComponent(
      () => import("@/components/Navigation/Header.vue")
    ),
    Footer: defineAsyncComponent(
      () => import("@/components/Navigation/Footer.vue")
    ),
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      const script = document.createElement("script");
      script.text = `(function(w, d, s, h, id) {
      w.roistatProjectId = id; w.roistatHost = h;
      var p = d.location.protocol == "https:" ? "https://" : "http://";
      var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
      var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
      })(window, document, 'script', 'cloud.roistat.com', '5700676b58856eb99d152166d0428a4c');`;
      document.body.append(script);

      const roistatScript = document.createElement("script");
      roistatScript.text = `new MutationObserver(function () {
          var _roistatGetCookie;

          const iframe = document.querySelector('iframe');
          if (iframe) iframe.src += ${"`&roistat=${(_roistatGetCookie = roistatGetCookie('roistat_visit')) !== null && _roistatGetCookie !== void 0 ? _roistatGetCookie : 'no found cookie'}`"};
        }).observe(document.body, {
          attributes: true,
          childList: true
        });`;
      document.body.append(roistatScript);
    }
  },
});
