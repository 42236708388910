import { Commit } from "vuex";

interface IResidents {
  FirstName: string;
  LastName: string;
  ActivityKind: string;
  Instagram: string;
  Photo: string;
  City: string;
}

interface IState {
  residents: Array<IResidents>;
}

interface IJson {
  Data: Array<IResidents>;
}

interface IPromiseFetch {
  ok: boolean;
  json: () => Promise<IJson>;
}

export default {
  state: {
    residents: [] as Array<IResidents>,
  },
  getters: {
    getResidents: (state: IState): Array<IResidents> => {
      return state.residents.sort((a, b) => {
        if (a.LastName > b.LastName) {
          return 1;
        } else if (a.LastName < b.LastName) {
          return -1;
        }
        return 0;
      });
    },
    getCountResidents: (state: IState): number => state.residents.length,
  },
  mutations: {
    setResidents: (state: IState, newResidents: Array<IResidents>): void => {
      state.residents = newResidents;
    },
  },
  actions: {
    async fetchResidents({ commit }: { commit: Commit }): Promise<void> {
      const fetchResidents: IPromiseFetch = await fetch(
        `${process.env.VUE_APP_API_URL}/api/person/clubmembers`
      );
      if (fetchResidents.ok) {
        const arrayResidents: Array<IResidents> = (await fetchResidents.json())
          .Data;
        commit("setResidents", arrayResidents);
      } else {
        commit("setResidents", []);
      }
    },
  },
};
